/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;      
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

    .custum-table{
      float: right;
        }

        .custom-main-div,.MuiTableRow-root.MuiTableRow-footer{
          width:98%;
          margin:0 auto;
        }

        /* tr td:nth-child(3),tr td:nth-child(4),tr th:nth-child(3),tr th:nth-child(4){
          position: -webkit-sticky;
          position: sticky;
          background-color: white;
          z-index: 2;
        }

        tr td:nth-child(3),tr th:nth-child(3){
          width: 300px !important;
          min-width: 300px;
          max-width: 300px;
          left: 0px;
          
        }
     tr td:nth-child(4),tr th:nth-child(4){
          width: 300px !important;
          min-width: 300px;
          max-width: 300px;
          left: 300px;
         
        }
        .main-div{
          overflow-y: auto;
          margin: auto;
          width: 1000px;
        } */

.Component-horizontalScrollContainer-12 .MuiTable-root tr th:first-child,
.Component-horizontalScrollContainer-12 .MuiTable-root tr td:first-child {display:none;}