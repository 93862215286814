
.spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .isResizable {   
    width: 100%;  
    height: 200px;  
    border: 1px solid #ccc; 
    padding: 10px;  
    resize: vertical;
    overflow: auto;
    }