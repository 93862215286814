/*.dynamic-form {
  box-shadow: 2px 6px 8px grey;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 10px 2px;
  max-width: 600px;
}
.dynamic-form .form-group {
  padding: 0.5em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.dynamic-form .form-label {
  text-align: left;
  color: #2e86c1;
}
.dynamic-form .form-input {
  margin-top: 5px;
  line-height: 1.5em;
}

.dynamic-form select {
  margin-top: 5px;
  line-height: 1.5em;
}

.dynamic-form .form-group button {
  padding: 0.5em;
}

.dynamic-form .form-group-radio {
  display: flex;
  flex-direction: row;
}

.form-group-radio label {
}

.form-group-radio input {
}

.dynamic-form .form-group-checkbox {
  display: flex;
  flex-direction: row;
}

.form-group-checkbox label {
}

.form-group-checkbox input {
}

.form-title {
  text-align: left;
}

.form-actions {
  display: flex;
}
.form-actions button {
  width: 80px;

  line-height: 1.5em;
  background-color: #3498db;
  color: white;
  border-radius: 4px;
  border: 0;
  margin-top: 15px;
  margin-left: 5px;
  margin-bottom: 5px;
}
*/
.autocomplete-wrapper div {
    position: initial !important;
}
.field-child-tooltip{
  color: #ccc;
  padding: 0 10px;
  position: relative;
  left: -25px
}
.field-tooltip{
  color: #ccc;
  padding: 0 10px;
  position: relative;
}

.upload-file-wrapper .empty-files{
  text-align: center;
  padding-top: 30px;
}

.icon-input-wrap{
  position: relative;
}
.icon-input-wrap,[input-type=text]{
  padding:  0 0 0 20px;
}
.text-icon{
  position: absolute;
  top: 60%;
  left: 3%;
}

.text-icon-right{
  position: absolute;
  top: 60%;
  right: 3%;
}
.text-icon-right-advance{
  position: absolute;
  top: 50%;
  right: 5%;
}
.icon-input{
  padding: 0 0 0 30px !important;
}

.requiredField label:after {
  content:" *";
  color: red;
}

.requiredField > div > label::after{
  content:" *" !important;
  color: red;
}

.requiredField > div > label::after{
  content:" *" !important;
  color: red;
}
.custom-radio > label::after{
  content: '';
}

.md-form input:not([type])+label::after, .md-form input[type="text"]:not(.browser-default)+label::after, .md-form input[type="password"]:not(.browser-default)+label::after, .md-form input[type="email"]:not(.browser-default)+label::after, .md-form input[type="url"]:not(.browser-default)+label::after, .md-form input[type="time"]:not(.browser-default)+label::after, .md-form input[type="date"]:not(.browser-default)+label::after, .md-form input[type="datetime"]:not(.browser-default)+label::after, .md-form input[type="datetime-local"]:not(.browser-default)+label::after, .md-form input[type="tel"]:not(.browser-default)+label::after, .md-form input[type="number"]:not(.browser-default)+label::after, .md-form input[type="search"]:not(.browser-default)+label::after, .md-form input[type="phone"]:not(.browser-default)+label::after, .md-form input[type="search-md"]+label::after, .md-form textarea.md-textarea+label::after {
  position: relative !important; 
  top: 0px !important;
  display: inline-block !important;
  opacity: 1 !important;
  transition: 0.2s opacity ease-out,0.2s color ease-out !important;
}
.tootipTrigger{
  background-color: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 0 !important;
}

.requiredField .custom-control-label:after{
  content: "";
}

.spin {
  animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }

  .isResizable {   
    width: 100%;  
    height: 200px;  
    border: 1px solid #ccc; 
    padding: 10px;  
    resize: vertical;
    overflow: auto;
    }