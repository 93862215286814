.SignCard {
  text-align: left;
  width: 100%;
  height: 100%;
}

.SignCard-header {
  text-align: center;
}

.disableSubmit {
  cursor: not-allowed;
  pointer-events: none;
  /* Button disabled - css color class */
  color: "#6c757d";
  background-color: "#6c757d";
}
.icon-input-wrap{
  position: relative;
}
.text-icon{
  position: absolute;
  top: 46%;
  left: 1%;
}
.icon-input{
  padding: 0 0 0 30px !important;
}
.icon-input-wrap .icon-show-password{
  position: absolute;
  top: 46%;
  right: 1%;
  cursor: pointer;
}
.md-outline .icon-show-password{
    position: absolute;
    top: 20%;
    right: 1%;
    cursor: pointer;
}